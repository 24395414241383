//externals: ['jquery']

import _ from 'lodash';

//// CUSTOM STYLES, EXPORTS & JS

import "./_custom-app.js";


/* # FUNCTIONS
========================================================================== */

$(function () {

    "use strict";
    var $window = $(window);
    var $document = $(document);
    var winWidthSm = 991;
    var $body = $('body');

    $(document).ready(function () {
        //fullScreenSlider();
        scroll();
        navigation_menu();
        //pluginElement();
        //sliderHero();
        //sliderAll();
        //containerGridMasonry();
        //scrollCallbackEle();
        //shortcodeElements();
    });


    // ---------------------------------------------------------------------------------------------------------------------------->
    // SCROLL FUNCTIONS   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->

    function scroll() {

        // //Click Event to Scroll to Top
        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {
                $('.scroll-top').fadeIn();
            } else {
                $('.scroll-top').fadeOut();
            }
        });
        $('.scroll-top').click(function () {
            $('html, body').animate({
                scrollTop: 0
            }, 800);
            return false;
        });

        // Scroll Down Elements
        $('.scroll-down[href^="#"], .scroll-to-target[href^="#"]').on('click', function (e) {
            e.preventDefault();

            var target = this.hash;
            var $target = $(target);

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top
            }, 900, 'swing', function () {
                window.location.hash = target;
            });
        });

    };

    // ---------------------------------------------------------------------------------------------------------------------------->
    // NAVIGATION MENU FUNCTIONS   ||-----------
    // ---------------------------------------------------------------------------------------------------------------------------->

    function navigation_menu() {

        var navMenuLink = $(".nav-menu > ul > li"),
            //menuIconBtn = $('.menu-dropdown-icon'),
            dropDown_Menu = $('.nav-dropdown'),
            nav_menu_item = $('.nav-menu-item'),
            nav_Mobile_Btn = $(".menu-mobile-btn"),
            nav_menu_wrap = $(".nav-menu");

        // Dropdown Menu Icon
        $('.nav-menu > ul > li:has( > .nav-dropdown)').prepend('<span class="menu-dropdown-icon"></span>');
        $('.nav-dropdown > ul > li:has( > .nav-dropdown-sub)').addClass('sub-dropdown-icon');
        $(".nav-menu > ul > li:has( > .nav-dropdown)").addClass('dd-menu-dropdown-icon');
        $window.on('resize', function () {
            if ($(window).width() > winWidthSm) {
                $('.nav-dropdown > ul > li ul li:has(.nav-dropdown-sub)').addClass('sub-dropdown-icon');
            };
            if ($(window).width() <= winWidthSm) {
                $('.nav-dropdown > ul > li ul li:has(.nav-dropdown-sub)').removeClass('sub-dropdown-icon');
            };
        });

        // Toogle hover header
        $("#header").on('mouseenter mouseleave', function (e) {

            var $nav = $("#header");
            $nav.toggleClass('hover');

        });

        // Dropdown Menu
        navMenuLink.on('mouseenter mouseleave', function (e) {
            if ($(window).width() > winWidthSm) {
                $(this).children(".nav-dropdown").stop(true, false).fadeToggle(150);
                e.preventDefault();
            }
        });
        $('.menu-dropdown-icon').on('click', function () {
            if ($(window).width() <= winWidthSm) {
                $(this).siblings(".nav-dropdown").stop(true, false).fadeToggle(150);
            }
        });
        $window.on('resize', function () {
            if ($(window).width() > winWidthSm) {
                $(".nav-dropdown, .nav-dropdown-sub").fadeOut(150);
            }
        });

        // Sub Dropdown Menu
        nav_menu_item.on('mouseenter mouseleave', function (e) {
            if ($(window).width() > winWidthSm) {
                $(this).children(".nav-dropdown-sub").stop(true, false).fadeToggle(150);
                e.preventDefault();
            }
        });
        nav_menu_item.on('click', function () {
            if ($(window).width() <= winWidthSm) {
                $(this).children(".nav-dropdown-sub").stop(true, false).fadeToggle(150);
            }
        });

        // Dropdon Center align
        $window.on('resize', function () {
            dropDown_Menu.each(function (indx) {
                if ($(this).hasClass("center")) {
                    var dropdownWidth = $(this).outerWidth();
                    var navItemWidth = $(this).parents(nav_menu_item).outerWidth();
                    var dropdown_halfWidth = parseInt(dropdownWidth / 2);
                    var navItem_halfWidth = parseInt(navItemWidth / 2);
                    var totlePosition = parseInt(dropdown_halfWidth - navItem_halfWidth);
                    if ($window.width() > winWidthSm) {
                        $(this).css("left", "-" + totlePosition + "px");
                    } else {
                        $(this).css("left", "0");
                    };
                }
            });
        });

        // Mobile Menu Button
        nav_Mobile_Btn.on('click', function (e) {
            nav_menu_wrap.toggleClass('show-on-mobile');
            $(this).toggleClass("active");
            e.preventDefault();
        });
    };



    //Sticky 
    $(function () {
        $(document).scroll(function () {
            var $header = $("#header");
            var $topBar = $("#top-nav");
            var $nav = $("#navigation");
            $header.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
            $topBar.toggleClass('d-none', $(this).scrollTop() > $nav.height());
        });
    });

    // ------------------------------------------------------- //
    //   Open & Close Search Panel
    // ------------------------------------------------------ //
    $('[data-toggle="search"]').on('click', function () {
        $('.search-area-wrapper').show();
        $('.search-area-input').focus();
    });

    $('.search-area-wrapper .close-btn').on('click', function () {
        $('.search-area-wrapper').hide();
    });


    // ------------------------------------------------------- //
    //   Home Full Slider
    // ------------------------------------------------------ //

    var homeFullSlider = $('.home-full-slider');
    homeFullSlider.owlCarousel({
        loop: true,
        margin: 0,
        smartSpeed: 500,
        responsiveClass: true,
        nav:false,
        autoplay:true,
        //navText: ['<img src="' + basePath + 'img/prev.svg" alt="" width="50">', '<img src="' + basePath + 'img/next.svg" alt="" width="50">'],
        responsive: {
            0: {
                items: 1,
                dots: true
            },
            600: {
                items: 1,
                dots: true
            },
            1120: {
                items: 1,
                dots: true
            }
        },

        onRefresh: function () {
            homeFullSlider.find('.item').height('');
        },
        onRefreshed: function () {
            var maxHeight = 0;
            var items = homeFullSlider.find('.item');
            items.each(function () {
                var itemHeight = $(this).height();
                if (itemHeight > maxHeight) {
                    maxHeight = itemHeight;
                }
            });
            items.height(maxHeight);
        }
    });

    // ------------------------------------------------------- //
    //   Home Slider
    // ------------------------------------------------------ //
    var homeSlider = $('.hero-slider-1');
    homeSlider.owlCarousel({
        loop: true,
        margin: 0,
        smartSpeed: 500,
        responsiveClass: true,
        nav:false,
        autoplay:true,
        //navText: ['<img src="' + basePath + 'img/prev.svg" alt="" width="50">', '<img src="' + basePath + 'img/next.svg" alt="" width="50">'],
        responsive: {
            0: {
                items: 1,
                nav: false,
                dots: true
            },
            600: {
                items: 1,
                nav: false,
                dots: true
            },
            1120: {
                items: 1,
                dots: false,
                nav: false
            }
        },
        onRefresh: function () {
            homeSlider.find('.item').height('');
        },
        onRefreshed: function () {
            var maxHeight = 0;
            var items = homeSlider.find('.item');
            items.each(function () {
                var itemHeight = $(this).height();
                if (itemHeight > maxHeight) {
                    maxHeight = itemHeight;
                }
            });
            items.height(maxHeight);
        }
    });

    // ------------------------------------------------------- //
    //   Home Slider
    // ------------------------------------------------------ //
    var homeSlider = $('.hero-slider-2');
    homeSlider.owlCarousel({
        loop: false,
        margin: 0,
        smartSpeed: 500,
        responsiveClass: true,
        nav:false,
        autoplay:true,
        //navText: ['<img src="' + basePath + 'img/prev.svg" alt="" width="50">', '<img src="' + basePath + 'img/next.svg" alt="" width="50">'],
        responsive: {
            0: {
                items: 1,
                nav: false,
                dots: true
            },
            600: {
                items: 1,
                nav: false,
                dots: true
            },
            1120: {
                items: 2,
                dots: false,
                nav: false
            }
        },
        onRefresh: function () {
            homeSlider.find('.item').height('');
        },
        onRefreshed: function () {
            var maxHeight = 0;
            var items = homeSlider.find('.item');
            items.each(function () {
                var itemHeight = $(this).height();
                if (itemHeight > maxHeight) {
                    maxHeight = itemHeight;
                }
            });
            items.height(maxHeight);
        }
    });

    // ------------------------------------------------------- //
    //   Item Slider
    // ------------------------------------------------------ //

    $('.gallery-slider').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        autoplay:true,
        //navText: ['<img src="../img/prev.svg" alt="" width="50">', '<img src="../img/next.svg" alt="" width="50">'],
        smartSpeed: 400,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: false,
                dots: true
            },
            576: {
                items: 2,
                nav: false,
                dots: true
            },
            768: {
                items: 3,
                dots: false,
                nav: true
            },
            992: {
                items: 3,
                dots: false,
                nav: true
            },
            1200: {
                items: 4,
                dots: false,
                nav: true
            },
            1400: {
                items: 5,
                dots: false,
                nav: true
            }
        }
    });

    // ------------------------------------------------------- //
    //    Colour form control 
    // ------------------------------------------------------ //
    
    $('.item-options-bn-colour').on('click', function (e) {
        var button = $(this);

        if (button.attr('data-allow-multiple') === undefined) {
            button.parents('.item-options-colours').find('.item-options-btn-colour').removeClass('active');
        }

        button.toggleClass('active');
    });

    // ------------------------------------------------------- //
    //  Button-style form labels used in detail.html
    // ------------------------------------------------------ //

    $('.item-options-btn-label').on('click', function () {
        var button = $(this);

        button.parents('.item-options').find('.item-options-btn-label').removeClass('active');

        button.toggleClass('active');
    });

    // ------------------------------------------------------- //
    // Quantity
    // ------------------------------------------------------ //

    $(function () {
        var qty_min = $('.quantity').attr("min");
        var qty_max = $('.quantity').attr("max");

        $(".quantityPlus").on('click', function () {
            var currentVal = parseInt($(this).next(".quantity").val(), 10);
            var str = $("p:first").text();
            if (currentVal != qty_max) {
                $(this).next(".quantity").val(currentVal + 1);
                $(this).next(".quantity").trigger("change");
            }
        });

        $(".quantityMinus").on('click', function () {
            var currentVal = parseInt($(this).prev(".quantity").val(), 10);
            if (currentVal != qty_min) {
                $(this).prev(".quantity").val(currentVal - 1);
                $(this).prev(".quantity").trigger("change");
            }
        });
    });

    // ------------------------------------------------------- //
    //   Tooltip
    // ------------------------------------------------------ //

    $('[data-toggle="tooltip"]').tooltip();


});